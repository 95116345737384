.grade-icon {
  display: grid;
  grid-template: 100% / auto 1fr;
  height: 30px;
  min-width: 80px;
  padding: 0 6px;
  border: 1px #000 solid;
  color: #000;

  @media all and (orientation: landscape) {
    @media all and (max-width: 820px) { min-width: 75px; }
  }

  @media all and (orientation: portrait) {
    @media all and (max-width: 420px) { min-width: 70px; }
    @media all and (max-width: 270px) { min-width: 65px; }
  }

  & > * { align-self: center; }

  &--AAA, 
  &--AA { background: #57e6c1; }
  &--AA18 { background: #fffa90; }
  &--DNP { background: #fc825f; }

  &__img-wrapper {
    display: flex;
    align-items: center;
    width: 15px;
    margin: 0 15%;
  }

  & p { margin-left: 6%; }
}

@import '../../common/mixins.scss';

.text-samples {
  font-family: "BauPro";
  font-size: 125%;
  text-align: left;
  
  & p { margin: 0 0 2% 0; }
}

.text-sample {
  &--normal { font-size: 1em; }
  &--largeBold,
  &--large { font-size: 1.5em; }
  &--largeBold,
  &--mobile { font-weight: 600; }
  &--unavailable { text-decoration: line-through; }
}

.row-item__overlay {
  position: relative;
  z-index: 10;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 100%;
  @include pseudo-border(bottom);
  @include pseudo-border(right, before);
}

.overlay__button-wrapper {
  position: absolute;
  top: 4%;
}

.overlay__info { 
  width: 100%; 
  height: 100%;
  text-align: left;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  & > *:not(:first-child) { margin-top: 6%; }

  & .overlay__text-samples { font-size: 1em; }
}

.overlay__ratio-label { font-size: 0.75em; }

.overlay__ratio {
  font-size: 5em;
  font-weight: 600;
  line-height: 100%;
  margin-top: 2%;
  white-space: nowrap;
  transition: color 0.25s ease;
}

.overlay__grade-container { 
  display: flex; 
  & > *:not(:first-child) { margin-left: 2%; }
}

// .overlay__background {
//   position: absolute;
//   // bottom: 0;
//   // right: 0;
//   z-index: -2;
//   height: 100vmin;
//   width: 100vmin;
//   background: rgba(0,0,0,0.5);
// }
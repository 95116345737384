* { box-sizing: border-box; }

html {
  font-size: 100%;
  @media all and (orientation: landscape) {
    @media all and (max-width: 1640px) { font-size: 95%; }
    @media all and (max-width: 1360px) { font-size: 90%; }
    @media all and (max-width: 980px) { font-size: 85%; }
    @media all and (max-width: 736px) { font-size: 80%; }
    @media all and (max-width: 480px) { font-size: 75%; }
  }

  @media all and (orientation: portrait) {
    @media all and (max-width: 980px) { font-size: 95%; }
    @media all and (max-width: 780px) { font-size: 90%; }
    @media all and (max-width: 560px) { font-size: 85%; }
    @media all and (max-width: 420px) { font-size: 80%; }
    @media all and (max-width: 270px) { font-size: 75%; }
  }
}

.App {
  text-align: left;
  background-color: #f2f2f2;
  font-family: 'Fugue';
  overflow: auto;
}

.main-page__container {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  display: grid;

  @media all and (orientation: landscape) {
    grid-template: 100vmin / minmax(33vmax, 1fr) 100vmin;
    overflow-x: auto;
    overflow-y: hidden;
  }

  @media all and (orientation: portrait) {
    // grid-template: minmax(40vmax, 1fr) 100vmin / 100vmin;
    grid-template: minmax(auto, 1fr) 100vmin / 100vmin;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.main-page__sidebar {
  padding: 5%;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  @media all and (orientation: landscape) { 
    overflow-y: auto; 
    overflow-x: hidden;
  }
}

.sidebar__top {
  width: 100%; 
  max-width: 500px;
  & h1 { margin-bottom: 5%; }
}

.sidebar__bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 50px;
  
  & .ua5-logo { 
    position: initial; 
    width: 3vmax;
    min-width: 35px;
  }
}

.sidebar__actions { 
  width: 100%; 
  & > *:not(:first-child) { margin-left: 5%; }
}

@import '../../common/mixins.scss';

.grade-toggle__container { 
  padding: 0; 
  max-width: 70vw;
  margin-bottom: 5%;
}

.grade-toggle {
  display: flex;
	align-items: center;
	font-family: 'BauPro';
  position: relative;
  min-height: 35px;
  &:not(:first-child) { margin-top: 5px; }

  & .grade-toggle__svg-checkbox,
  & .grade-icon { margin-right: 7.5px; }
}

.grade-toggle__checkbox,
.grade-toggle__svg-checkbox {
  height: 25px;
  width: 25px;
  min-width: 25px;
  background: #fff;
  cursor: pointer;
}

.grade-toggle__checkbox {
  position: absolute;
  margin: 0;
  opacity: 0;

  &:focus + .grade-toggle__svg-checkbox { @include blue-outline; }

  &:not(:checked):not(:hover):not(:focus) 
  ~ .grade-toggle__label-container {
    filter: grayscale(100%);
    opacity: 0.4;
  }
}

.grade-toggle__label-container {
  display: flex;
  align-items: center;
  width: 100%;
  transition: opacity 0.25s linear;
}
@mixin color-picker-placement($orientation, $both, $first, $last) {
  @media all and (orientation: #{$orientation}) {
    & .get-started__edit-wrapper { #{$both}: -2px;  }
    & .hex-add__form--absolute { #{$both}: -1px; }
  
    &:first-child {
      & .get-started__edit-wrapper { #{$first}: -2px;  }
      & .hex-add__form--absolute { #{$first}: -1px; }
    }
  
    &:last-child {
      & .get-started__edit-wrapper { #{$last}: -2px;  }
      & .hex-add__form--absolute { #{$last}: -1px; }
    }
  }
}

@mixin diagonal-border {
  background: linear-gradient(
    to top right,
    rgba(0,0,0,0) 0%,
    rgba(0,0,0,0) calc(50% - 0.8px),
    #848484 50%,
    rgba(0,0,0,0) calc(50% + 0.8px),
    rgba(0,0,0,0) 100%
  );
}

@mixin blue-outline {
  // outline: -webkit-focus-ring-color auto 5px;
  outline: none; 
  -webkit-box-shadow: 0px 0px 5px 2.5px  #4D90FE;
  box-shadow: 0px 0px 5px 2.5px #4D90FE;
}

@mixin pseudo-border($side, $pseudo: after) {
  position: relative;
  
  &::#{$pseudo} {
    content: ' ';
    position: absolute;
    z-index: 2;
    #{$side}: 0;
    border-#{$side}: #000 solid;

    @if $side == 'top' or $side == 'bottom' {
      width: 100%;
      height: auto;
      left: 0;
    } 
    @else {
      width: auto;
      height: 100%;
      top: 0;
    }
  }
}

@import '../../common/mixins.scss';

.row-item,
.row-item__content {
  height: 100%;
  width: 100%;
}

.row-item {
  padding: 0;
  position: relative;
  // &:not(:first-child) { border-left: #000 solid; }
  @include pseudo-border(right);
}

.row-item--hidden .row-item__content:not(:hover):not(:focus) {
  filter: grayscale(100%);
  opacity: 0.4;
}

.row-item__content {
  padding: 7.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: filter 0.25s ease, opacity 0.25s ease;
  
  &:active { 
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.text-samples {
  // overflow: hidden;
  font-family: "BauPro";
  font-size: 125%;
  text-align: left;
  
  & p { margin: 0 0 2% 0; }
}

.text-sample {
  &--normal { font-size: 1em; }
  &--largeBold,
  &--large { font-size: 1.5em; }
  &--largeBold,
  &--mobile { font-weight: 600; }
  &--unavailable { text-decoration: line-through; }
}
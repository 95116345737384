.grade__container {
  display: flex;
  width: 50%;
  min-width: 125px;
  // height: 35px;
  height: 30px;

  & > * { height: 100% !important; }

  & .grade-icon { text-align: left; }

  & .ratio {
    width: 50%;
    padding: 4% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #000;
  }
}

.grade__container--small {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30%;
  width: 100%;
  min-width: 0;

  & > * { padding: 0; }

  & .grade-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    width: 35%; 
    border-right: none;
    border-bottom: none;
    border-left: none;

    & p { display: none; }

    &__img-wrapper { 
      min-width: 7px;
      margin: 0;
    }
  }

  & .ratio {
    width: 65%;
    
    & > p { font-weight: 600; }
  }
}

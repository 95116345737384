.hex-edit__container {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
}

.hex-edit__form {
  position: absolute;

  &--absolute {
    // bottom: 0;
    // right: 0;
  }
  
  &--centered {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@import '../../common/mixins.scss';

.get-started__container {
  height: 100vh;
  width: 100vw;
  display: grid;
  
  @media all and (orientation: landscape) {
    grid-template: 100vmin / minmax(auto, 1fr) 50vmin;
  }
  @media all and (orientation: portrait) {
    grid-template: minmax(auto, 1fr) 50vmin / 100vmin;
  }
}

.get-started__sidebar {
  position: relative;
  padding: 5%;

  & .ua5-logo {
    position: absolute;
    width: 8vmin;
    max-width: 89px;
    bottom: 5%;
    right: 5%;
  }
}

.get-started__sidebar__content {
  overflow-y: visible;
  display: flex;
  flex-flow: column;
  & .button--text-only { margin-left: 1%; }
  
  & .get-started--small-margin { 
    width: 70%;
    // min-width: 300px;
    min-width: 275px;
  }

  & .get-started--small-margin > * { margin: 4% 0 0 0; }

  @media all and (orientation: landscape) {
    & p, .button--text-only { font-size: 1.25rem; }
  }

  & .get-started__multiple-trigger a { cursor: pointer; }

}

.get-started__title {
  font-size: 7rem;
  max-width: 620px;

  @media all and (orientation: landscape) {
    @media all and (max-width: 1050px) { font-size: 6rem; }
    @media all and (max-width: 940px) { font-size: 5rem; }
    @media all and (max-width: 800px) { font-size: 4rem; }
    @media all and (max-width: 500px) { font-size: 3rem; }
  }

  @media all and (orientation: portrait) {
    @media all and (max-width: 550px) { font-size: 6rem; }
    @media all and (max-width: 450px) { font-size: 5.5rem; }
    @media all and (max-width: 400px) { font-size: 5rem; }
    @media all and (max-width: 350px) { font-size: 4rem; }
  }
}

.get-started__selection {
  height: 100vmin;
  width: 50vmin;
  background: #FFF;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;

  & > * { min-height: 20px; }

  & .color-add--multiple { width: 50%; }

  & .color-add--multiple a { padding-bottom: 10%; }

  @media all and (orientation: portrait) {
    height: 50vmin;
    width: 100vmin;
  }
}

.get-started__selection--multiple {
  border: 4px #000 solid;
  padding: 5%;

  @media all and (orientation: portrait) {
    flex-flow: column nowrap;
    overflow-y: auto;

    & > *:not(:first-child),
    & .get-started-button--multiple { margin-top: 7.5%; }

    & > .color-add--multiple { min-height: 200px; }
  }
}

.color-selection__input-wrapper {
  width: 100%;
  border-bottom: 1px #000 solid;
  margin-bottom: 25px;
  position: relative;

  & > * { display: inline; }
  
  &:before {
    content: "#";
    font-size: 1.25rem;
    font-weight: 600;
    position: absolute;
  }

  & button:not(.button--anchor) {
    width: 50px;
    height: 50px;
  }

  & .multipleInput__delete {
    position: absolute;
    right: 0;
  }
}

.get-started__square-container {
  height: 50vmin;
  width: 50vmin;
  position: relative;

  border: 1px #000 solid;
  &:last-child {
    @media all and (orientation: landscape) { border-top: none; }
    @media all and (orientation: portrait) { border-left: none; }
  }

  // Overwrite absolute positioning in grid
  & .hex-edit__form { position: initial !important; }

  & .get-started__edit-wrapper, .hex-add__form {
    position: absolute;
    z-index: 5;
  }

  // Positioning for color pickers
  @include color-picker-placement(landscape, right, top, bottom);
  @include color-picker-placement(portrait, bottom, left, right);
}

.get-started__square {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 5%;

  & .color-selection__add {
    width: 10vmin;
    height: 10vmin;
    border-width: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > * {
    margin: 0;
    min-height: 20px;
  }

  & .button--text-only { 
    // visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s linear, visibility 0.25s linear;
  };
  
  &:hover .button--text-only,
  &:focus .button--text-only,
  & .button--text-only:hover,
  & .button--text-only:focus {
    // visibility: visible;
    opacity: 1;
  }
}

.flex-container--column {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.get-started-button--multiple {
  margin-top: 15%;
  width: 100%;
  padding: 8% 0%;
}

.get-started-button--hidden { visibility: hidden; }


@import '../../common/mixins.scss';

.hex-grid__container,
.hex-grid__row {
  height: 100%;
  width: 100%;
}

.hex-grid__container,
.hex-grid__header--first,
.hex-grid__header--text,
.hex-grid__header--background,
.hex-grid__row-container,
.hex-grid__row { display: grid; }

.hex-grid__header--first {
  grid-template: repeat(3, 1fr) / 1fr;
  padding: 7.5%;
  border-bottom: #000 solid;
  border-right: #000 solid;
  @include diagonal-border;
  
  &::after, &::before { border-width: 1px !important; }

  &__x-label {
    grid-row-start: 2;
    place-self: center end;
  }
  
  &__y-label {
    grid-row-start: 3;
    place-self: end center;
  }
}

.hex-grid__header--first,
.hex-grid__header--text { 
  @include pseudo-border(top);
}

.hex-grid__header--first,
.hex-grid__header--background { 
  @include pseudo-border(left, before); 
}

.hex-grid__row { @include pseudo-border(bottom); }
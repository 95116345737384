@import '../../common/mixins.scss';

.color-selection,
.color-selection__square-wrapper {
  width: 100%;
  height: 100%;
}

.color-selection__square-wrapper,
.color-selection__add {
  display: flex;
  justify-content: center;
}

.color-selection__square-wrapper {
  position: relative;

  &--icon { cursor: pointer; }

  & > p { position: absolute; }

  &:focus .color-selection__add { 
    border-radius: 50%;
    @include blue-outline; 
  }
}
@import '../../common/mixins.scss';

.grid-header__container {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  font-weight: 600;
  transition: color 0.25s ease;

  @include pseudo-border(bottom);
  @include pseudo-border(right, before);
}

.grid-header__info,
.grid-header__edit-wrapper {
  height: 100%;
  width: 100%;
}

.grid-header__options,
.grid-header__hex  {
  transition: opacity 0.25s linear;
}

.grid-header__options {
  opacity: 0;

  &.grid-header__options--small-square {
    width: 100%;
    text-align: center;
  }

  & .button--text-only:not(:last-child) { margin-right: 5px; }
}

.grid-header__info {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 7.5%;

  & > * { min-height: 20px; }

  &:hover > .grid-header__options,
  .grid-header__options:hover,
  .grid-header__options:focus-within {
    opacity: 1;
  }
}

.grid-header__info--small {
  & > .grid-header__options {
    width: 100%;
    text-align: center;
  }

  &:hover > .grid-header__hex,
  & .grid-header__options:hover + .grid-header__hex,
  & .grid-header__options:focus-within + .grid-header__hex {
    opacity: 0;
    visibility: hidden;
    text-decoration: none;
  }
}



@import './common/mixins.scss';

@font-face {
  font-family: 'BauPro';
  src: local('BauPro'), url(assets/fonts/BauPro.otf) format('OpenType');
}

@font-face {
  font-family: 'Fugue';
  src: local('fugue'), url(assets/fonts/fugue.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Text */
h1 {
  font-family: "Fugue";
  font-size: 5rem;
  font-weight: 400;
  line-height: 100%;
  margin: 0;
  width: auto;
}

p {
  font-family: "BauPro";
  font-size: 1rem;
  margin: 0;
}


button.button--anchor,
button.button--text-only {
  background: none;
}

button.button--text-only, {
  position: relative;
  padding: 0 0 1px 0;

  &::after {
    position: absolute;
    width: 100%;
    bottom: -2px;
    content: ' ';
    left: 0;
    border-bottom: 1px solid;
  }
}

button.link--black {
  &:link,
  &:visited,
  &:hover,
  &:active { color: #000; }
}

input[type="text"] {
  border: none;
  font-family: "Fugue";
  font-size: 1rem;
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
}

.text--small,
.text--small-bold {
  font-size: 0.855rem;
}

.text--bold,
.text--small-bold {
  font-weight: 600; 
}

.text--small-bold { 
  letter-spacing: 0.5px;
}

@media all and (max-width: 1690px) { 
  html { font-size: 100%};
}
@media all and (max-width: 1280px) { 
  h1 { font-size: 3.25rem};
}
@media all and (max-width: 980px) { 
  h1 { font-size: 3rem};
}
@media all and (max-width: 736px) { 
  h1 { font-size: 2.75rem};
}
@media all and (max-width: 480px) { 
  h1 { font-size: 2.5rem};
}
@media all and (max-width: 350px) { 
  h1 { font-size: 2.25rem};
}

/* Buttons */

// Override default styles
button {
  border: none;
  cursor: pointer;
}

button:not(.button--text-only):not(.button--anchor) {
  background: #000;
  color: #FFF;
  font-family: "BauPro";
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  width: 100%;
  padding: 4% 8%;
}

/* SVGs and IMGs */
.img-svg { width: 100%; }

/* UI Elements */
input[type="checkbox"] {list-style-type: none; }

.row-item--empty {
  height: 100%;
  width: 100%;
  border: none;
  font-weight: 600;
  text-transform: capitalize;
  @include diagonal-border;
}

.hex-add__form {
  & * { touch-action: none; }
  // @include pseudo-border(top);
  // @include pseudo-border(left, before);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
}
.chrome-picker {
  border-radius: 2px 2px 0 0 !important;
  box-shadow: none;
  width: 245px !important;

  &+button {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px;
    border-radius: 0 0 2px 2px !important;
  }

  & > div:nth-child(2) > div:nth-child(2) {
    & > div:nth-child(2) { display: none; }

    & > div.flexbox-fix > div > div {
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      
      & > span { margin: 0 12px 0 0 !important; }
    }
  }
}